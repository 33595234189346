import { InfiniteData } from '@tanstack/react-query';

export const editElement = <T extends { id: string }>(
  newValue: T,
  key: keyof T & string = 'id'
) => {
  return <Y extends { items: T[] }>(oldData: InfiniteData<Y, unknown> | undefined) => {
    console.log('newValue', newValue);
    if (!oldData) return oldData;

    // Find the page containing the item
    const pageIndex = oldData.pages.findIndex((page) =>
      page.items.some((value) => value[key] === newValue[key])
    );

    if (pageIndex < 0) {
      // Return oldData if the item is not found
      return oldData;
    }

    const page = oldData.pages[pageIndex];

    // Find the item in the page
    const itemIndex = page.items.findIndex((value) => value[key] === newValue[key]);

    if (itemIndex < 0) {
      // Return oldData if the item is not found
      return oldData;
    }

    // Create new items array with the updated item
    const newItems = [...page.items];
    newItems[itemIndex] = newValue;

    // Create the new page with updated items
    const newPage = {
      ...page,
      items: newItems
    };

    // Create new pages array with the updated page
    const newPages = [...oldData.pages];
    newPages[pageIndex] = newPage;

    // Return new InfiniteData with updated pages
    return {
      ...oldData,
      pages: newPages
    };
  };
};
